<template>
  <ModuleSubPage>
    <WelcomeMessage />
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import WelcomeMessage from "@views/Restricted/Dashboard/DashboardWidgets/WelcomeMessage/WelcomeMessage.vue";
export default {
  name: "DashboardWidgets",

  components: {
    ModuleSubPage,
    WelcomeMessage,
  },
};
</script>
