<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat color="primary300" class="px-8 py-10 mt-7">
          <div class="content-wrapper">
            <BaseHeadline size="3" class="mb-4">
              {{ headline }}
            </BaseHeadline>

            <BaseText v-html="$t('DASHBOARD.WIDGET_WELCOME_SUBLINE')" />
            <img :src="deskPerson" class="content-image" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeskPerson from "@/assets/img/studentDesk.svg";

export default {
  name: "WelcomeMessage",

  computed: {
    ...mapGetters("person", ["personData", "hasPersonData"]),

    headline() {
      const user = { userName: this.personData.fullName || undefined };

      return this.loggedInRecently
        ? this.$t("DASHBOARD.WIDGET_WELCOME_TEXT_REVISIT", user)
        : this.$t("DASHBOARD.WIDGET_WELCOME_TEXT_FIRST", user);
    },

    deskPerson() {
      return DeskPerson;
    },
  },

  methods: {
    async goToSeminarSearch() {
      try {
        await this.$router.push({ name: "SeminarSearch" });
      } catch {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  &-wrapper {
    position: relative;
  }
  &-image {
    position: absolute;
    height: 185px;
    top: -65px;
    right: -50px;
  }
}
</style>
